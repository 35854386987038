.mega-badge {
    border-radius: 1px;
    background: linear-gradient(180deg,
            rgba(255, 255, 255, 0.00) 4.26%,
            rgba(255, 255, 255, 0.10) 4.27%,
            rgba(255, 255, 255, 0) 52.06%),
        #5587AC;
    box-shadow: 0px 1px 3px 0px map-get($color, mega2);
    padding: 6px 12px;
    font-size: .85rem;
    letter-spacing: .05rem;
}

.small-badge {
    padding: 4px 16px;
    margin: 1.4rem 0;
    background-color: map-get($color, button-lt);
    background-image: linear-gradient(map-get($color, button-lt), map-get($color, button-dk));
    width: 160px;
    font-size: 1rem;
    line-height: normal;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
}

.small-badge2 {
    padding: .8rem 0 .5rem;
    color: darken(map-get($color, text), 20);
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: .02rem;
    text-transform: uppercase;

}

.featured-badge {
    padding: 4px 16px;
    margin: 1rem 0;
    background-color: map-get($color, button-lt);
    background-image: linear-gradient(map-get($color, accent1), darken(map-get($color, accent1),6));
    width: 180px;
    color: map-get($color, bg);
    font-size: 1rem;
    line-height: normal;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
}

.mega-button {
    border-radius: 1px;
    color: map-get($color, bg);
    background-color: map-get($color, text);
    padding: 6px 22px;
    box-shadow: none;
    text-decoration: none;
    box-shadow: unset;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: .1s;

    &:hover {
        color: lighten(map-get($color, bg), 10);
        padding: 6px 22px;
    }
}

.big-button {
    color: map-get($color, bg);
    background-color: map-get($color, text);
    font-size: 1.1rem;
    font-weight: 500;
    padding: 8px 20px;
    box-shadow: none;
    text-transform: uppercase;

    &:hover {
        color: map-get($color, mega1);
        background-color: lighten(map-get($color, text), 10);
        // padding: 14px 30px;
    }
}
.small-button {
    color: map-get($color, bg);
    border: none;
    background-color: map-get($color, text);
    font-size: 1.1rem;
    font-weight: 500;
    padding: 6px 30px;
    box-shadow: none;
    text-transform: uppercase;

    &:hover {
        color: map-get($color, accent2);
    }

    &:active {
        color: map-get($color, accent1);
        background-color: darken(map-get($color, text), 20);
    }
}

.service-button {
    color: map-get($color, text);
    background-color: map-get($color, bg);
    border-radius: .09rem;
    background: linear-gradient(180deg,
        map-get($color, bg) 0%,
        #2C4151 24%,
        map-get($color, bg) 100%
    );
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.20);
    font-size: 1rem;
    font-weight: 500;
    padding: 12px 20px;
    box-shadow: none;
    text-transform: uppercase;

    &:hover {
        color: map-get($color, accent1);
        padding: 12px 20px;
    }
}