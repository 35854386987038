#mission {
    margin-left: auto;
    margin-right: auto;
    width: map-get($size, tablet);

    @include media('<=tablet') {
        width: map-get($size, content-width-m);
        margin-top: 3rem;
        padding: 6rem 1rem;

        h2 {
            text-align: center;
        }
    }

    p {
        &:first-of-type {
            margin-top: 1.3rem;
            font-weight: 400;
        }

        font-size: 1.2rem;
        line-height: normal;
    }
}

#capabilities {
    width: 100%;
    // margin: 4rem 0 0 0;
}

#reviews {
    margin: 8rem 0;
    font-size: 2rem;
    width: 100%;

    h2,
    p {
        text-align: center;
    }

    @include media ('<=tablet') {

        .review-first {
            margin: 0 auto;
            width: map-get($size, content-width-m);
        }
    }
}

#work {
    margin: 8rem auto;
    width: 100%;
    display: grid;
    // grid-template-columns: 4fr 5fr;
    grid-template-columns: repeat(2, 1fr);

    @include media('<=desktop') {
        margin: 0 auto;
    }
    @include media('<=tablet') {
        width: 100%;

        grid-template-columns: 1fr;
        justify-items: center;
    }

    .text {
        padding-left: 13.5vw;
        align-self: center;
        justify-content: end;

        @include media('<=tablet') {
            padding-left: unset;
        }

        h2 {
            font-size: 1.75rem;
            line-height: 2.6rem;

            span {
                display: block;
                font-size: 3.1rem;
            }
        }

        p {
            margin-bottom: 2rem;
        }
    }
}

#contact {
    margin: 8rem auto;
    width: 660px;

    @include media('<=tablet') {
        width: map-get($size, content-width-m);
        text-align: center;

        p {
            text-align: center;
        }
    }

    p {
        font-size: 1.2rem;
        line-height: normal;
        margin-bottom: 2rem;
    }
}