.blog-listing-container {

    margin: 0 auto;
    width: map-get($size, content-width);

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 80px;

    @include media('<=desktop') {
        width: 78vw;
        grid-template-columns: 1fr;
    }

    .blog-card {

        width: 100%;
        display: grid;
        grid-template-columns: 200px 1fr;
        gap: 20px;
        align-items: start;

        @include media('<=tablet') {
            grid-template-columns: 1fr;
        }

        .thumb {
            img {
                width: 200px;
                height: 200px;
            }
        }

        .info {

            width: 100%;

            h2 {
                font-size: 1.4rem;
                line-height: 1.6rem;
                margin-bottom: 10px;

                @include media('<=tablet') {
                    font-size: calc(1rem + 1.5vw);
                }
            }

            p {
                margin-bottom: 30px;
            }

            .meta {

                font-size: 1rem;
                margin-bottom: 20px;
            }
        }
    }
}

.blog-hero {
    margin: 0 auto;
    width: map-get($size, blog-width);

    @include media('<=tablet') {
        width: 100%;
    }
}

.blog-article-container {
    margin: 0 auto;
    width: map-get($size, blog-width);

    display: grid;
    grid-template-columns: 1fr 200px;
    grid-row-gap: 20px;
    grid-column-gap: 50px;

    .blog-meta {
        grid-area: 1 / 1 / 2 / 3;

        margin-top: 20px;
        // margin-bottom: -40px;
        font-size: 1.1rem;
        letter-spacing: .08px;
        font-weight: 300;

        a {
            box-shadow: none;
        }

        span {
            margin-right: 2px;
        }

        div {
            margin: 6px 0;

            span {
                font-weight: 500;
            }
        }
    }

    ul {
        padding: .5rem 1rem;

        li {
            margin-bottom: .5rem;
            line-height: 1.2rem;
        }
    }

    .tags {

        margin-top: 30px;

        span {
            display: block;
            font-weight: 400;
        }

        ul {
            list-style-type: none;
            display: inline-block;
            margin: 0 0 0 .5rem;
            padding: 0;

            li {
                display: inline-block;
                text-transform: uppercase;
                font-size: .9rem;
                margin-right: 6px;

                a {
                    letter-spacing: .02rem;
                    box-shadow: none;
                    border-radius: 5px;
                    padding: 2px 4px;
                    background-color: lighten(map-get($color, bg), 10);

                    &:hover {
                        background-color: map-get($color, accent2);
                    }
                }
            }
        }
    }

    .blog-content {
        grid-area: 2 / 1 / 3 / 2;

        h2,
        h3 {
            margin-top: 20px;
            margin-bottom: 10px;
        }

        p {
            font-size: 1.25rem;
            line-height: 1.5rem;
        }

        ul {
            li {
                font-size: 1.25rem;
                line-height: 1.5rem;
            }
        }


    }

    .blog-sidebar {
        grid-area: 2 / 2 / 3 / 3;
    }

    @include media('<=tablet') {
        width: 90%;
        grid-template-columns: 1fr;

        .blog-meta,
        .blog-content,
        .blog-sidebar {
            grid-area: unset;
        }
    }

}