.featured {

    margin: 0 auto 2rem auto;
    width: map-get($size, content-width);

    display: grid;
    grid-template-columns: 75% 25%;
    gap: 3rem;

    @include media('<=desktop') {
        width: map-get($size, content-width-m);
    }

    @include media('<=tablet') {
        grid-template-columns: 1fr;
    }

    .text {

        ul {
            margin-left: 1rem;
        }
    }

    .image {
        align-self: center;
    }

}

.featured-gallery {
    margin: 0 auto 4rem auto;
    width: map-get($size, content-width);

    @include media('<=desktop') {
        width: map-get($size, content-width-m);
    }

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.4rem;

    @include media('<=tablet') {
        grid-template-columns: 1fr;
    }
}

.work-container {
    margin: 0 auto;
    width: map-get($size, content-width);

    @include media('<=desktop') {
        width: map-get($size, content-width-m);
    }

    .work-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 4rem;
        margin-bottom: 2rem;

        @include media('<=tablet') {
            grid-template-columns: 1fr;
        }

        .card {
            width: 100%;
            background-color: map-get($color, card);
            background: radial-gradient(circle,
                    map-get($color, card1) 0%,
                    map-get($color, card2) 100%);
            padding: 2rem;
            transition: map-get($time, short);

            @include media('<=tablet') {
                min-height: unset;
            }

            &:hover {
                border-radius: 3px;
                box-shadow: 0 0 4px map-get($color, button-lt);
            }

            h3 {
                font-size: 1.125rem;
                text-transform: uppercase;
                letter-spacing: .05rem;
                text-shadow: none;
            }

            p {
                margin-top: 1rem;
                color: darken(map-get($color, text),10);
                font-size: 1rem;
                line-height: normal;
            }
        }
    }
}

#works {
    margin-bottom: 2rem;
}