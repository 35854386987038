.about-container {
    margin: 0 auto;
    width: map-get($size, content-width);
    width: 1000px;

    @include media('<=desktop') {
        width: map-get($size, content-width-m);
    }
}
.about-grid {
    margin: 4rem 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 8rem;
    align-items: start;

    @include media('<=desktop') {
        grid-template-columns: 1fr;
        grid-row-gap: 3rem;
    }

    h2 {
        margin-bottom: .5rem;
    }
    h3 {
        margin-bottom: 1rem;
    }

    img {
        border-radius: map-get($misc, br-sm);
    }


}
.about-grid-wide {
    margin: 0 auto;
    padding-bottom: 2rem;
    text-align: center;

    p {
        text-align: center;
        margin-bottom: 2rem;
    }
}