.review-card-container {
    margin: 2rem auto;
    width: map-get($size, desktop);
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;

    @include media('<desktop') {
        width: 100%;
    }

    @include media('<tablet') {
        width: map-get($size, content-width-m);
        grid-template-columns: 1fr;

        p {
            &:first-of-type {
                margin: 0 auto;
            }
        }
    }

    .card {
        position: relative;
        width: 385px;
        box-shadow: 0 0 .05rem map-get($color, text);
        background: radial-gradient(circle,
                map-get($color, card1) 0%,
                map-get($color, card2) 100%);
        padding: 2rem 1.5rem;

        @include media('<=desktop') {
            width: map-get($size, content-width-m);
        }

        @include media('<=tablet') {
            width: 86%;
            justify-self: center;
        }

        .stars {
            margin: 1.2rem 0 2rem 0;

            span {
                margin-right: 4px;
            }

            ;
        }

        p {
            padding-right: 2rem;
            margin-bottom: 2rem;
            font-size: 1.1rem;
            line-height: 1.35rem;
            font-weight: 300;
            text-shadow: 0.04rem 0.04rem 0 map-get($color, bg);

            // I don't ilke that this is !important.
            // Is A CONTAINER QUERY the way to go here?
            text-align: left !important;
        }



        cite {
            position: absolute;
            bottom: 10px;
            padding: 4px 20px 0 0;
            width: 80%;
            font-size: 1rem;
            line-height: 2rem;
            font-weight: 600;
            box-shadow: 0 -.025rem 0 0 map-get($color, text);
        }
    }
}

// Homepage Case Study Card
.case-study-container {

    .case-study {
        position: relative;
        padding: 1.8rem;
        width: 300px;
        min-height: 400px;
        background-color: map-get($color, card);
        background: radial-gradient(circle,
                map-get($color, card1) 0%,
                map-get($color, card2) 100%);
        box-shadow: 0 0 .05rem map-get($color, text);

        @include media('<=tablet') {
            margin-top: 2rem;
        }

        h3 {
            // color: map-get($color, accent1);
            font-size: .9rem;
            letter-spacing: 0.05rem;
            text-transform: uppercase;
        }

        h4 {
            color: map-get($color, button-lt);
            font-size: .875rem;
            font-weight: 400;
            text-transform: uppercase;
        }

        p {
            margin: .4rem 0 0 0;
            margin-bottom: 2rem;
            font-size: 1rem;
            line-height: 1.2rem;
            font-weight: 400;
        }

        a {
            position: absolute;
            bottom: 1.4rem;
            font-size: .9rem;
            line-height: normal;
            color: map-get($color, text);
            box-shadow: 0 .04rem 0 map-get($color, accent1);

            &:hover {
                color: map-get($color, accent1);
                box-shadow: map-get($color, accent1);
                padding-bottom: .2rem;
            }
        }

        img {
            box-shadow: 0 0 .05rem map-get($color, text);
        }
    }
}

// Services Card
.service-card-container {

    margin-bottom: 6rem;

    .service-card2 {
        position: relative;
        margin-left: auto;
        margin-right: auto;
        margin-bottom:  4rem;
        border-radius: 5px;
        width: map-get($size, content-width);
        height: 335px;

        @include media('<=desktop') {
            width: 85%;
            background-repeat: no-repeat;
            background-size: cover;
        }
        @include media('<=tablet') {
            background-image: none !important;
            background-color: map-get($color, text);
            height: 400px;
        }

        .text {
            position: absolute;
            top: 100px;
            right: 0;
            width: 525px;

            @include media('<=desktop') {
                width: 50%;
                right: 0;
            }

            @include media('<=tablet') {
                top: 66px;
                margin-left: auto;
                margin-right: auto;
                width: 85%;
            }

            h2 {
                color: map-get($color, bg);
                text-shadow: 1px 1px 0 map-get($color, text);
            }
            p {
                padding-right: 5rem;
                color: map-get($color, bg);
                text-shadow: 1px 1px 0 map-get($color, text);
                font-weight: 400;
                margin-bottom: 1.2rem;
            }
        }
    }

    .service-card {

        margin: 6rem auto;
        width: map-get($size, content-width);
        display: grid;
        grid-template-columns: repeat(100, 1fr);
        gap: 1px;

        @include media('<=desktop') {
            width: 85%;
        }
        @include media('<=tablet') {
            grid-template-columns: 1fr;
            gap: unset;
        }

        .image {
            grid-column: span 48;
        }

        .text {
            grid-column: span 52;
            padding: 3.25rem 3rem;
            background-color: map-get($color, text);
            color: map-get($color, bg);

            h2 {
                color: map-get($color, bg);
                font-size: 1.75rem;
                line-height: normal;
                text-transform: capitalize;
            }

            p {
                font-size: 1.2rem;
                line-height: normal;
                margin-bottom: 2rem;
            }
        }
    }
}

// About Cards
.about-work-container {

    margin: 4rem auto;
    padding-top: 2rem;
    width: map-get($size, content-width);

    @include media('<=desktop') {
        width: map-get($size, content-width-m);
    }


    h2 {
        text-align: center;
        margin-top: 2rem;
    }

    p {
        text-align: center;
    }

    .about-work-grid {

        margin: 2rem auto;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;
        justify-items: center;

        @include media('<=desktop') {
            grid-template-columns: 1fr;
        }

        .about-work-card {

            position: relative;
            padding: 1.8rem;
            width: 300px;
            min-height: 400px;
            background-color: map-get($color, card);
            background: radial-gradient(circle,
                    map-get($color, card1) 0%,
                    map-get($color, card2) 100%);
            box-shadow: 0 0 .05rem map-get($color, text);

            h3 {
                // color: map-get($color, accent1);
                font-size: .9rem;
                letter-spacing: .05rem;
                text-transform: uppercase;
            }

            h4 {
                color: map-get($color, button-lt);
                font-size: .875rem;
                font-weight: 400;
                text-transform: uppercase;
            }

            p {
                margin: .4rem 0 0 0;
                margin-bottom: 2rem;
                font-size: 1rem;
                line-height: 1.2rem;
                font-weight: 400;
                text-align: left;
            }

            a {
                position: absolute;
                bottom: 1.4rem;
                font-size: .9rem;
                line-height: normal;
                color: map-get($color, text);
                box-shadow: 0 .04rem 0 map-get($color, accent1);

                &:hover {
                    color: map-get($color, accent1);
                    box-shadow: map-get($color, accent1);
                    padding-bottom: .2rem;
                }
            }

            img {
                box-shadow: 0 0 .05rem map-get($color, text);
            }
        }
    }
}