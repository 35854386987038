.mega-container {
    margin: 8rem auto;
    width: map-get($size, desktop);
    height: auto;

    display: grid;
    grid-template-columns: 5fr 6fr;
    gap: 4px;

    @include media('<=desktop') {
        width: 100%;
        grid-template-columns: 1fr;
    }

    @include media('<=tablet') {
        margin-top: unset;
    }


    .mega-pic {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        min-height: 260px;
    }

    .mega-text {
        padding: 4rem 3rem 3rem 4rem;
        background-image: radial-gradient(map-get($color, mega1), map-get($color, mega2));
        font-size: .8rem;
        font-weight: 500;
        text-transform: uppercase;
    }

    h2 {
        margin: 1rem 0;
        color: map-get($color, text);
        text-shadow: 1px 0 .02rem map-get($color, bg);
        text-transform: unset;
    }

    p {
        // padding-right: 3rem;
        font-size: 1.2rem;
        line-height: normal;
        font-weight: 300;
        text-transform: none;
        margin-bottom: 2.4rem;
    }
}