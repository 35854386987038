// SASS SCSS styles. Loading first those files that others depend on

// LIBS
// These files are required by others so they are loaded first.
@import '/libs/include-media';
@import 'libs/vars';
@import 'libs/anims';

// COMPONENTS
@import 'components/hero';
@import 'components/mega';
@import 'components/buttons';
@import 'components/cards';
@import 'components/forms';

// LAYOUT
@import 'layout/wrapper';
@import 'layout/content';
@import 'layout/work';
@import 'layout/about';
@import 'layout/header';
@import 'layout/home';
@import 'layout/blog';
@import 'layout/list';
@import 'layout/footer';

// LAYOUT

// BASE
@import '/base/reset';
@import '/base/page';
@import '/base/typography';

// LET'S LOAD SOME GOOGLE FONTS
// ANTON for the titles
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
// Proxima Nova for the body copy
@import url("https://use.typekit.net/tzp3fih.css");

::selection {
    color: lighten(map-get($color, accent2), 10);
    background: map-get($color, bg);
  }