form {
    label {
        display: block;
        margin-bottom: .25rem;
        padding-left: .1rem;
        font-family: map-get($family, body);
        font-size: 1.2rem;
        line-height: normal;
        font-weight: 500;
        text-transform: uppercase;
    }

    input {
        width: 100%;
        height: 56px;
        color: map-get($color, text);
        font-family: map-get($family, body);
        font-size: 1.25rem;
        line-height: normal;
        font-weight: 500;
        padding: 0 .5rem;
        border: .5px solid map-get($color, text);
        background-color: lighten(map-get($color, bg), 10);
        margin-bottom: 1rem;
    }
    textarea {
        margin-bottom: .5rem;
        padding: .5rem;
        width: 100%;
        height: 200px;
        color: map-get($color, text);
        font-family: map-get($family, body);
        font-size: 1.25rem;
        line-height: normal;
        font-weight: 500;
        border: .5px solid map-get($color, text);
        background-color: lighten(map-get($color, bg), 10);
    }

    .captcha {
        margin-bottom: 1.2rem;
    }
}