.hero {
    height: 340px;
        // background: url('/images/abstract3.png');
    background-size: cover;
    background-position: top right;

    h1 {
        position: relative;
        padding: 2rem 10%;

        @include media('<=tablet') {
            width: 100%;
            margin: 0 auto;
            padding: 1rem 10%;
            font-size: 3.2rem;
            line-height:  3.6rem;
            text-align: center;
        }

        span {
            color: map-get($color, accent1);
            letter-spacing: .1rem;

            span {
                color: map-get($color, accent2);
                // animation: highlight-me 3s ease 1;
            }
        }
    }

    p {
        position: relative;
        width: 880px;
        padding: 0 10%;
        font-size: 1.375rem;
        line-height: normal;

        @include media('<=desktop') {
            width: 100%;
        }
    }

    @include media('<=tablet') {
        height: 250px;
    }
}

.no-height {
    height: auto;
}

.hero-image {
    position: absolute;
    background: url('/images/abstract-hero.jpg');
    background-repeat: no-repeat;
    background-position: center right;
    background-size: cover;
    height: 500px;
    width: 100%;
    z-index: -2;

    @include media('<=tablet') {
        background-image: none;
    }
}

.hero-fade {
    position: absolute;
    background-image: linear-gradient(90deg,
    map-get($color, bg) 50%,
    map-get($color, bg-a) 90%
    );
    background-repeat: no-repeat;
    background-position: top right;
    height: 526px;
    width: 100%;
    z-index: -1;

    @include media('<=tablet') {
        background-image: none;
    }
}