html {
    font-size: 16px;
}

body,
input,
select,
textarea {
    font-family: map-get($family, body);
    font-weight: map-get($weight, normal);
    font-size: 1.2rem;
    line-height: 1.4em;
    color: map-get($color, text);

    a {
        color: map-get($color, text);
        font-weight: 300;
        text-decoration: none;
        box-shadow: 0 .06rem 0 map-get($color, accent1);
        transition: map-get($time, short);
        text-shadow: -.4px 0 0 map-get($color, text);
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: map-get($color, text);
    font-weight: 300;
    letter-spacing: .04rem;
}

h1 {
    font-family: map-get($family, heading);
    font-size: 3.8rem;
    line-height: 4.2rem;
    font-weight: 300;
    word-break: keep-all;
    color: map-get($color, text);
    margin: 35px 0 18px 0;
    text-transform: uppercase;
    text-shadow:
        4px 2px 0 darken(map-get($color, bg), 1);
}

h2 {
    font-family: map-get($family, heading);
    color: map-get($color, text);
    font-size: 2rem;
    line-height: 2.1rem;
    margin-bottom: 1rem;
    text-transform: uppercase;
}

h3 {
    font-family: map-get($family, heading);
    font-size: 20px;
    line-height: 24px;
    // font-weight: 600;
    margin-bottom: 4px;
}

h4 {
    font-size: 18px;
    line-height: 24px;
    // font-weight: 600;
}

p {
    font-size: 1.2rem;
    line-height: normal;
    font-weight: 300;
    margin-bottom: 10px;
    text-align: left;
}

a:hover {
    color: lighten(map-get($color, accent1), 10);
    text-decoration: none;
    padding-bottom: .61rem;
    box-shadow: unset;
}

blockquote {
    color: map-get($color, accent1);
    font-weight: 300;
    font-size: 34px;
    line-height: 36px;
}

cite {
    font-family: map-get($family, body);
    font-weight: map-get($weight, normal);
    font-style: normal;
}

strong {
    font-weight: 500;
}

ul,
li {
    padding-left: 0;
}
