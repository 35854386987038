header {
    position: relative;
    top: 0;
    left: 0;
    margin-left: 40px;
    display: grid;
    grid-template-columns: 146px auto;
    width: 730px;
    z-index: 1;

    @include media ('<=tablet') {
        display: none;
    }

    .logo {

        width: 140px;

        img {
            width: 100%;
            filter: drop-shadow(0 0 2px lighten(map-get($color, bg), 6));
        }

    }

    menu {
        margin: 2.6rem 0 0 2rem;
        padding: 0;

        list-style-type: none;

        li {
            display: inline;
            margin-right: 1.5rem;

            &:last-of-type {
                margin-right: unset;
            }

            a {
                box-shadow: none;
                color: map-get($color, text);
                font-size: 1.2rem;
                font-weight: 500;
                transition: map-get($time, short);
                text-shadow: -1px 2px 0 map-get($color, bg);

                &:hover {
                    color: map-get($color, accent1);
                }
            }
        }

        .active {
            color: map-get($color, accent1);
            text-shadow: unset;

            &:hover {
                color: lighten(map-get($color, accent1), 20);
                text-shadow: unset;
            }
        }
    }
}

// Mobile Menu
.mobile-top {
    display: none;

    @include media('<=tablet') {
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 2rem;
        width: 80%;
        display: grid;
        grid-template-columns: 40px auto 40px;

        .logo-mobile {
            place-self: center;
            z-index: 10;

            img {
                width: 140px;
            }
        }
    }

}

// MOBILE NAV STUFF

@keyframes bugfix {
    from {
        padding: 0;
    }

    to {
        padding: 0;
    }
}

@-webkit-keyframes bugfix {
    from {
        padding: 0;
    }

    to {
        padding: 0;
    }
}

#overlay-button {
    display: none;
    position: absolute;
    left: 1.6em;
    top: 2.4em;
    padding: 26px 11px;
    z-index: 201;
    cursor: pointer;
    user-select: none;

    @include media('<=tablet') {
        display: block;
    }

    span {
        height: 4px;
        width: 35px;
        border-radius: 2px;
        background-color: map-get($color, text);
        position: relative;
        display: block;
        transition: all map-get($time, short) ease-in-out;

        &:before {
            top: -10px;
            visibility: visible;
        }

        &:after {
            top: 10px;
        }

        &:before,
        &:after {
            height: 4px;
            width: 35px;
            border-radius: 2px;
            background-color: map-get($color, text);
            position: absolute;
            content: "";
            transition: all map-get($time, short) ease-in-out;
        }
    }

    &:hover span,
    &:hover span:before,
    &:hover span:after {
        background-color: map-get($color, text);
    }
}

input[type=checkbox] {
    display: none;

    &:checked~#overlay {
        visibility: visible;
    }

    &:checked~#overlay-button {

        &:hover span,
        span {
            background: transparent;
        }

        span {
            &:before {
                transform: rotate(45deg) translate(7px, 7px);
                opacity: 1;
            }

            &:after {
                transform: rotate(-45deg) translate(7px, -7px);
            }
        }
    }
}

#overlay {
    height: 100%;
    width: 100vw;
    top: 0;
    background: map-get($color, bg);
    z-index: 200;
    visibility: hidden;
    position: fixed;
    text-align: center;

    &.active {
        visibility: visible;
    }

    .menu-boxes {
        margin: 0 auto;
        margin-bottom: 30px;
        width: 96vw;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;

        a {
            display: block;
            width: 100%;
            height: 20vh;
            padding-top: 4.1rem;
            background-color: map-get($color, card);
            background: radial-gradient(circle,
                map-get($color, card1) 0%,
                map-get($color, card2) 100%);
            box-shadow: none;
            border-radius: 12px;
            border: .5px solid darken(map-get($color, text), 30);
            font-size: 1.4rem;
            font-weight: 500;
            letter-spacing: .02rem;
            text-shadow: 0 0 6px black;
            text-transform: uppercase;

            &:hover {
                background-color: map-get($color, accent1);
            }
        }

    }
    .overlay-contact {
        box-shadow: none;
        text-transform: uppercase;
    }

    .logo-overlay {
        img {
            width: 140px;
            margin: 2rem auto;
        }
    }

    .overlay-legal {
        position: absolute;
        width: 100%;
        bottom: 1rem;
        color: darken(map-get($color, text), 30);
        text-align: center;
        font-size: .75rem;
    }
}

