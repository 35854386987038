.blinkie {
    animation: blink .75s ease-in-out infinite;
}

@keyframes blink {
    25% {
        opacity: 1;
    }
    70% {
        opacity: 0.05;
    }
    90% {
        opacity: 1;
    }
}

.slide {
    animation: down map-get($time, short) ease-in-out 1;
}

@keyframes down {
    0% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

@keyframes highlight-me {
    0% {
        color: map-get($color, text);
        // letter-spacing: .04rem;
    }
    100% {
        color: map-get($color, accent2);
        // letter-spacing: .1rem;
    }
}