.content {
    // position: relative;
    width: map-get($size, showoff);
    margin: 0 auto;
    padding-top: 2rem;
    transition: map-get($time, medium);

    @include media('<=showoff') {
        width: map-get($size, desktop);
    }

    @include media('<=desktop') {
        width: 100%;
    }

    @include media('<=tablet') {
        padding-top: 2rem
    }
}

.inner {
    position: relative;
    // margin-top: 380px;
}

.tech-grid-container {

    h2 {
        text-align: center;
        margin-bottom: 2rem;
    }

    .tech-grid {

        margin: 0 auto 2rem auto;
        width: map-get($size, content-width);
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 2rem;
        justify-items: center;

        @include media('<=desktop') {
            width: 85%;
            grid-template-columns: repeat(2, 1fr);
        }

        img {
            border-radius: map-get($misc, br-sm);
            filter: opacity(.9);
        }
    }
}

.contact-grid {
    margin: 0 auto;
    width: 90%;

    display: grid;
    grid-template-columns: 4fr 6fr;
    grid-column-gap: 4rem;

    div {
        &:nth-child(2) {
            margin-top: 1.8rem;
        }
    }

    @include media('<=desktop') {
        width: map-get($size, content-width-m);
    }

    @include media('<=desktop') {
        grid-template-columns: 1fr;
        grid-row-gap: 1rem;
    }
}

.legal-container {
    padding: 1rem 4rem;
}

// MISC
.small-type {

    margin-top: 2rem;
    padding-right: 3rem;
    font-size: .8rem;
    line-height: normal;
}

.hidden{
    display: none;
}

.four-oh-four {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 300px;
    width: 60vw;

    img {
        margin: 15px 0;
    }

    @include media('<=tablet') {
        width: 90vw;
    }
}