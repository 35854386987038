// TYPOGRAPHY
// Font Family
$family: (
    heading:    ('Anton', sans-serif),
    // body:       ('Nunito Sans', sans-serif),
    body:       ('Proxima Nova', sans-serif),
);

// Font Weight
$weight: (
    normal:           300,
    heavy:            400
);

// Sizes & Spacing
//   doublecheck sizes from include-media.scss
//     showoff - 1440px
//     desktop - 1280px
//     tablet - 768px
//     mobile - 600px
//     phone - 425px
  $size: (
    showoff:        1440px,
    desktop:        1280px,
    tablet:          768px,
    mobile:          600px,
    phone:           425px,
    content-width:  1000px,
    content-width-m:   85%,
    blog-width:        80%,
);

// Time & Duration
$time: (
    short:          .3s,
    medium:         .6s,
    long:           1.2s
);

// Color Palette
$color: (
    // bg:             #182c3b,
    bg:             #12222e,
    bg-a:           #12222e00,
    bg2:            #060f16,
    mega1:          #527E9F,
    mega2:          #36698f,
    text:           #dbe2e7,
    accent1:        #d4d815,
    accent2:        #be6cff,
    accent-dark:    #081722, // Black Pearl
    card:           #3b4a56,
    card1:          #3b4a56,
    card2:          #2b3945,
    button-lt:      #5587AC,
    button-dk:      #42759B,
);

$misc: (
    br-sm:  5px,
);