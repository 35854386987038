footer {
    min-height: 240px;
    background-color: map-get($color, accent-dark);
    box-shadow: 0 0 .025rem white;
    margin-top: 4rem;

    .footer-content {

        width: map-get($size, desktop);
        margin-left: auto;
        margin-right: auto;
        padding-top: 2rem;
        display: grid;
        grid-template-columns: 4fr 5fr;

        @include media('<=showoff') {
            width: 90vw;
        }

        @include media('<=tablet') {
            grid-template-columns: 1fr;
        }

        .resources {

            display: grid;
            grid-template-columns: repeat(2, auto);
            padding: 10px 80px;
            justify-self: center;

            .top-line {
                font-size: 1.2rem;
                line-height: 1.8rem;
                font-weight: 500;
            }

            ul {
                list-style-type: none;
                margin: 8px;

                li {
                    font-size: 1rem;
                    line-height:  1.4rem;

                    a {
                        box-shadow: none;
                    }
                }
            }
        }

        .legal {

            .copyright {
                text-align: center;

                img {
                    margin: 10px auto;
                    width: 84px;
                }

                .info {
                    width: 96%;

                    p {
                        text-align: center;
                        color: map-get($color, button-lt);
                        font-size: .8rem;
                        line-height: .98rem;
                        font-weight: normal;
                        margin-bottom: 1rem;

                        a {
                            color: map-get($color, button-lt);
                            box-shadow: none;
                            font-weight: 400;
                            padding-bottom: 0;

                            &:hover {
                                color: map-get($color, text);
                                box-shadow: none;
                            }
                        }
                    }
                }
            }
        }
    }
}