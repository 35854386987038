.wrapper {
    position: relative;
    width: 100%;
    // height: 100vh;
}

.das-pixel {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    width: 12px;
    height: 12px;
    background-color: map-get($color, accent1);
    z-index: 10;

    @include media('>2000px') {
        display: block;
    }
}

.das-glow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 515px;
    background-image: radial-gradient(
        farthest-corner at 10px -200px,
        lighten(map-get($color, bg), 7),
        map-get($color, bg)
    );
    z-index: -1;

    @include media('<=tablet') {
        height: 66vh;
        background-image: none;
    }
}