.list-container {
    margin: 0 auto;
    width: map-get($size, blog-width);

    display: grid;
    grid-template-columns: 1fr;
    gap: 120px;

    @include media('<=tablet') {
        grid-template-columns: 1fr;
    }

    .list-card {

        width: 100%;
        display: grid;
        grid-template-columns: 100px 1fr;
        gap: 30px;
        align-items: start;

        @include media('<=tablet') {
            grid-template-columns: 1fr;
        }

        .thumb {
            img {
                width: 100px;
                height: 100px;
            }
        }

        .info {

            h2 {
                font-size: 1.4rem;
                margin-bottom: 10px;

                @include media('<=tablet') {
                    font-size: calc(1rem + 1.5vw);
                }
            }

            p {
                margin-bottom: 10px;
            }

            .meta {

                font-size: 1rem;
                margin-bottom: 10px;

                span {
                    margin-left: 10px;
                }
            }
        }
    }
}

.blog-hero {
    margin: 0 auto;
    width: map-get($size, blog-width);

    @include media('<=tablet') {
        width: 100%;
    }
}

.blog-article-container {
    margin: 0 auto;
    width: map-get($size, blog-width);

    display: grid;
    grid-template-columns: 1fr 200px;
    grid-row-gap: 20px;
    grid-column-gap: 50px;

    .blog-meta {
        grid-area: 1 / 1 / 2 / 3;

        margin-top: 20px;
        // margin-bottom: -40px;
        font-size: 1.1rem;
        letter-spacing: .08px;
        font-weight: 300;


        div {
            margin: 6px 0;

            span {
                font-weight: 500;
            }
        }
    }

    .blog-content {
        grid-area: 2 / 1 / 3 / 2;

        h2, h3 {
            margin-top: 20px;
            margin-bottom: 10px;
        }

    }

    .blog-sidebar {
        grid-area: 2 / 2 / 3 / 3;
    }

    @include media('<=tablet') {
        width: 90%;
        grid-template-columns: 1fr;

        .blog-meta, .blog-content, .blog-sidebar {
            grid-area: unset;
        }
    }

}